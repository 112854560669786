<template>
  <transition name="fade">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="400"
      class="rounded-xl"
      scrollable
    >
      <v-card class="pb-5" rounded="lg">
        <v-card-title
          class="text-h6 text--darken-3 d-flex justify-space-between pt-1 pe-1 pb-1"
        >
          <v-spacer></v-spacer>
          <CloseBtn size="30" :callback="callbackClose" />
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="text-center">
          <v-img
            max-width="200"
            class="mx-auto mb-4"
            :src="require('@/assets/island/Hamochi.png')"
          >
          </v-img>
          <div class="mb-2">
            <div class="text-h4">{{ $t("string.comingSoon") }}</div>
            <div class="text-h2">
              {{ $moment(timestampStart).diff($moment(), "days") }}
            </div>
            <div>
              {{ $t("string.days_start") }}
            </div>
          </div>
          <v-btn
            color="yellow"
            v-if="settings.debug"
            @click="claimBook()"
            :loading="api.isLoading"
          >
            <v-icon color="red" small> mdi-record </v-icon>
            {{ $t("action.claim") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: ["showDialog", "callbackClose", "timestampStart", "bookKey"],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    //
  },
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  methods: {
    claimBook() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/claim";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.$router.go();
      };
      this.api.params = {
        bookKey: this.bookKey,
        isDebug: this.settings.debug,
      };
      this.$api.fetch(this.api);
    },
  },
};
</script>
