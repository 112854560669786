<template>
  <transition name="fade">
    <ApiErrorDialog v-if="api.isError" :api="api" />
    <RewardDialog v-if="claimReward" :callbackClose="exitRwd" :showDialog="true" :rewards="reward" />
    <LevelUpDialog v-if="levelUP" :callbackClose="exitlevelUP" :showDialog="true" :name="selectedItem.name" :from="selectedItem.previousLevel"
      :to="selectedItem.currentLevel" />
    <AchievementDetailsDialog v-if="details" :callbackClose="() => (details = false)" :showDialog="true"
      :details="selectedDetails" />
    <v-dialog v-model="showDialog" persistent max-width="400" class="rounded-xl" scrollable>
      <v-card v-if="items.length < 1" class="pb-5" rounded="lg">
        <div class="d-flex justify-center align-center" style="height:150px">
          <v-progress-circular indeterminate color="primary" size="50" width="5"></v-progress-circular>
        </div>
      </v-card>
      <v-card v-else class="pb-5" rounded="lg">
        <v-card-title class="pt-1 pe-1">
          <v-spacer></v-spacer>
          <CloseBtn size="30" :callback="callbackClose" />
        </v-card-title>
        <!-- <AbstractAvatar :avatar="auth.Player.avatar" :height="100" :width="120"
          class="mx-auto d-flex align-center justify-center mb-2"></AbstractAvatar> -->
        <v-card-text class="text-center">
          <!-- <div class="text-caption black--text">{{ auth.Student ? auth.Student.name : auth.User.name }}</div>
          <div class="text-h5  black--text">{{ $t("string.avatarLevel") }} {{ xp.currentLevel }}</div>
          <v-progress-linear :value="(xp.currentXp / xp.nextXpThreshold) * 100" striped color="amber" rounded
            class="mx-auto text-caption" style="max-width: 150px" height="30">
            {{
              xp.currentXp + " / " + xp.nextXpThreshold + " XP"
            }}
          </v-progress-linear>

          <v-divider class="my-5"></v-divider> -->
          <!-- <div class="text-h6 mb-2">{{ $t("string.achievements") }}</div> -->
          <v-row>
            <v-col cols="4" v-for="item in items" :key="item.achievementKey">
              <v-card flat @click="
                selectedDetails = item;
              details = true;
              ">
                <!-- <v-img :src="item.currentLevel.isRewardClaimed && item.nextLevel
                  ? item.nextLevel.imageUrl
                  : item.currentLevel.imageUrl
                  "></v-img> -->
                <v-img :src="item.currentLevel.imageUrl"></v-img>
                <div class="text-caption">{{ item.name }}</div>
                <!-- <div>{{ item.currentLevel.isRewardClaimed && item.nextLevel ? item.nextLevel.name : item.currentLevel.name }}</div> -->
                <div>{{ item.currentLevel.name }}</div>
                <v-progress-linear :key="item.currentLevel.levelKey + 'mp'" :value="item.currentLevel.isRewardClaimed && item.nextLevel
                  ? Math.max(3, (item.nextLevel.progress / item.nextLevel.milestone) * 100)
                  : Math.max(3, (item.currentLevel.progress / item.currentLevel.milestone) * 100)
                  " color="orange" rounded height="5"></v-progress-linear>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    // AbstractAvatar: () =>
    //   import(
    //     /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
    //   ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    LevelUpDialog: () =>
      import(
        /* webpackChunkName: "component-level-up-dialog" */ "@/components/LevelUpDialog.vue"
      ),
    AchievementDetailsDialog: () =>
      import(
        /* webpackChunkName: "component-achivement-detail-dialog" */ "@/components/AchievementDetailsDialog.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/ABC/RewardDialog"
      ),
  },
  props: ["showDialog", "callbackClose"],
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    items: [],
    xp: {},
    claimReward: false,
    levelUP: false,
    reward: [],
    selectedItem: {},
    selectedDetails: {},
    details: false,
  }),
  async mounted() {
    await this.getAchievements();
  },
  methods: {
    async exitRwd() {
      this.claimReward = false;
      await this.getAchievements();
    },
    async exitlevelUP() {
      this.levelUP = false;
      await this.claimAchievement(this.selectedItem.currentLevel.levelKey);
    },
    async getAchievements() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/achievement/visit";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.items = resp.Achievement;
        this.xp = resp.Level;
        for (const item of this.items) {
          if (
            item.currentLevel.progress >= item.currentLevel.milestone &&
            !item.currentLevel.isRewardClaimed
          ) {
            this.selectedItem = item;
            this.levelUP = true;
            break;
          }
        }
      };
      await this.$api.fetch(this.api);
    },
    async claimAchievement(levelId) {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/achievement/claim";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.reward = resp.Reward;
        this.claimReward = true;
      };
      this.api.params = {
        levelKey: levelId,
      };
      await this.$api.fetch(this.api);
    },
  },
};
</script>
